import styled from '@emotion/styled'

import { MAX_WIDTH } from '@/constants/style/layout'

export const MainTemplate = () => {
  return (
    <MainTemplateContainer>
      <MainTemplateInnerContainer></MainTemplateInnerContainer>
    </MainTemplateContainer>
  )
}

const MainTemplateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
`

const MainTemplateInnerContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`
