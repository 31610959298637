import { ChannelTalk } from '@/components/common/features/channel-talk'
import { MainTemplate } from '@/components/template/main'
import { RootLayout } from '@/layout/root-layout'
import { NextPageWithLayout } from '@/pages/_app'

const MainPage: NextPageWithLayout = () => {
  return (
    <>
      <MainTemplate />
    </>
  )
}

export default MainPage

MainPage.getLayout = (page) => {
  return (
    <ChannelTalk>
      <RootLayout>{page}</RootLayout>
    </ChannelTalk>
  )
}
